.wrap {
    position: relative;
}

.input_wrap {
    position: relative;
}

.input {
    background: #fff;
    width: 100%;
    color: var(--text-color-black);
    border-radius: 6px;
    border: 1px solid var(--border-grey);
    font-size: 14px;
    padding: 5px 8px;

    &:focus, &:hover {
        border: 1px solid var(--orange-accent);
    }
}

.btn_show_password {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}