@mixin white-block {
    background: #fff;
    border-radius: 16px;
    padding: 20px;
}

@mixin input-general {
    background: #fff;
    width: 100%;
    color: var(--text-color-black);
    border-radius: 6px;
    border: 1px solid var(--border-grey);
    font-size: 14px;
    padding: 5px 8px;

    &:focus, &:hover {
        border: 1px solid var(--orange-accent);
    }
}

@mixin shadow {
    box-shadow: 0px 3.2px 9px 0px #00000029;
    box-shadow: 0px 0.6px 1.8px 0px #0000001A;
    box-shadow: 0px -1.5px 6px 0px #0000000F;
}

.notices {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;

    .item {
        display: flex;
        border-radius: 8px;
        min-height: 45px;
        align-items: flex-start;
        padding-top: 12px;
        padding-bottom: 12px;

        &.info {
            border: 1px solid #1BA049;
            background: url(/assets/img/notice-info.svg) no-repeat 10px 10px;
            background-color: #EAFCF1;
            background-size: 24px 24px;
            padding-left: 45px;
        }

        &.error {
            border: 1px solid #D92020;
            background: url(/assets/img/notice-error.svg) no-repeat 10px 10px;
            background-color: #FFEFEF;
            background-size: 24px 24px;
            padding-left: 45px;
        }

        &.blue {
            border: 1px solid #0062FF;
            background: url(/assets/img/notice-blue.svg) no-repeat 10px 10px;
            background-color: #EDF5FF;
            background-size: 24px 24px;
            padding-left: 45px;
        }

        .bold {
            font-weight: bold;
        }
    }
}