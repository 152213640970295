.main {
    
    max-width: var(--layout-width);
    margin: 0 auto;
    width: 100%;
    flex-grow: 1;
    margin-bottom: 20px;
    min-height: 0;

    &.scrollable {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.orange_bg {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: var(--orange-bg);
}