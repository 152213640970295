.page {
    display: flex;
    flex-direction: column;
    width: 100%;


    padding: 0;

    max-height: 100%;
    overflow: auto;
}

.page:not(.transparent) {
    background: #fff;
    border-radius: 16px;
    padding: 20px;
}

.page.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}

.page.exp {
    height: 100%;
}

.pagetitle {
    width: 100%;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: var(--text-color-black);
    flex-grow: 0;
    flex-shrink: 0;
}

.pagecontent {
    width: 100%;
    flex-grow: 1;
}

.pagecontent.nodata {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nodata-msg {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;

    
}

.nodata-msg p {
    margin-top: 5px;
    margin-bottom: 10px;
}

.big-msg {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.big-msg p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0;
}