@import '../../assets/css/common';

.wrap {
    position: relative;
}

.input {
    @include input-general;
}

.error .input {
    border: 1px solid var(--red);
}

.error_msg {
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 16px;
    color: var(--red);
}