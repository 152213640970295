.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--layout-width);
    margin: 0 auto;
    border-bottom: 1px solid #D5D8DE;
    height: 60px;
    flex: 0 0 60px;

    .site {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .sitename {
        display: block;
        color: var(--orange-accent);
        font-weight: 900;
        font-size: 28px;
        line-height: 32px;
    }

    .sitetitle {
        text-transform: uppercase;
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
    }

    .topmenu {
        display: flex;
        align-items: center;
        gap: 24px;

        .sep {
            width: 1px;
            flex: 0 0 1px;
            height: 20px;
            background: #D5D8DE;
        }
    }

    .user_info {
        color: #646973;
        font-size: 12px;
        line-height: 16px;

        .email {
            color: #23262D;
            line-height: 20px;
            font-size: 14px;
        }
    }
}

.notice_done {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #fff;
    border-radius: 16px;
    padding: 8px;
    margin: 20px 0;

    .msg {
        flex-grow: 1;
        font-weight: 700;
    }
}

.scales {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    background: #fff;
    border-radius: 16px;
    padding: 8px;
    margin: 20px 0;

    .head {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        font-weight: 700;
        flex-grow: 1;
        padding: 12px;

        label {
            color: var(--text-color-black);
            cursor: pointer;
        }
    }

    .scale {
        flex: 0 0 160px;
        padding: 12px;
        border-radius: 12px;

        .title {
            font-weight: 700;
        }

        .hint {
            font-size: 12px;
        }

        &.active {
            background: var(--grey-light);
        }
    }
}

.bigmsg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    gap: 8px;
}

.block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    cursor: wait;
    z-index: 9999;
    display: none;
    opacity: 0.3;

    &.shown {
        display: block;
    }
}