@import '../../assets/css/common';

.company_item {
    @include white-block;

    display: block;
    position: relative;

    h2 {
        margin: 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
    }

    button {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .items {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 20px;

        .item:last-child:not(:first-child) {
            border-left: 1px solid #D5D8DE;
            padding-left: 12px;
        }
    }
}