.progress_outer {
    display: block;
    position: relative;
    height: 8px;
    border-radius: 4px;
    background: var(--grey-med);
}

.progress_inner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: var(--orange-accent);
    border-radius: 4px;
}