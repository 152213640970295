.form {
    display: block;

    .form_header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
    }

    .form_footer {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
    }

    .form_body {
        display: flex;
        align-items: center;
        gap: 10px;

    }

    .form_col {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .form_element {
        display: block;

        &.flex {
            display: flex;
            gap: 20px;

            .form_element {
                flex-grow: 1;
            }
        }
    }
}