@import '../../assets/css/common';

.list {
    display: block;
    width: 100%;

    .assessment_item {
        display: block;
        position: relative;
        color: var(--text-color-black);

        @include white-block;
        padding: 0;
        
        margin-bottom: 20px;

        .a {
            display: block;
            color: var(--text-color-black);
            padding: 20px;
        }

        .btn_actions {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            color: var(--text-color-black);
        }

        .info {
            border-bottom: 1px solid #D5D8DE;
            display: flex;
            gap: 20px;
            padding-bottom: 20px;
            padding-top: 10px;
        }

        .info_btm {
            padding-top: 20px;
            display: flex;

            h3 {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
            }

            .info1 {
                flex: 0 0 50%;
            }

            .info2 {
                flex: 0 0 50%;
                padding-left: 12px;
                border-left: 1px solid #D5D8DE;
            }

            .items {
                padding-top: 8px;
                display: flex;
                align-items: center;
                gap: 12px;

                .item:last-child {
                    border-left: 1px solid #D5D8DE;
                    padding-left: 12px;
                }

                .bold {
                    font-weight: 700;
                }
            }
        }
    }
}


.assessment_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    max-height: 100%;
}

.header_block {
    @include white-block;
    
    

    .header_block_line {
        display: flex;
        align-items: center;
    }

    .chips {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-left: 20px;
    }

    .notices {
        margin-top: 20px;
        margin-bottom: 0;
    }
}

.content_block {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    gap: 8px;
    min-height: 0;
}

.side_block {
    @include white-block;
    padding: 10px;

    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
}

.side_widget {
    
    border-radius: 16px;
    padding: 12px;
    cursor: pointer;

    &.active {
        background: var(--grey-light);
    }

    &:hover {
        background: var(--grey-med);
    }

    h3 {
        margin-bottom: 20px;
    }

    .pbar {
        margin-bottom: 4px;
    }

    .stats {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        line-height: 20px;

        &:not(.total) {
            margin-top: 12px;
        }

        .value {
            font-weight: 700;
        }
    }

    .horline {
        display: block;
        background: #D5D8DE;
        height: 1px;
        width: 100%;
        margin: 12px 0;
    }
}

.main_block {
    @include white-block;

    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .tabs {
        margin-top: 10px;
        flex-grow: 1;
    }
}

.grid_toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 12px;
}

.respondents {
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.dlg_add, .dlg_add > div {
    //height: 720px;
    width: 512px;
    max-width: none;
    background: #fff;
}

.dlg_add {
    right: 50%;
    margin-right: var(--minus-half-layout-width);

    .field {
        margin-bottom: 15px;
    }

    .buttons {
        margin-top: 60px;
        display: flex;
        gap: 10px;
    }
}

.btn_actions {
    border-radius: var(--border-radius-btn);
    border: 1px solid var(--orange-accent);
    color: var(--orange-accent);
    background: #fff;
    width: 203px;
    text-align: center;

    svg {
        margin-left: 6px;
    }
}

.fio_link {
    cursor: pointer;
    color: var(--text-color-black) !important;
}

.dlg_results {
    max-width: 1056px;
    height: 650px;
    
    > div {
        width: 1056px;
        height: 650px;
    }

    table .gr {
        background: #F1F3F6;
    }

    tr {
        border-bottom: 1px solid #D5D8DE;
    }

    th, td {
        padding: 8px 12px;
        line-height: 20px;
    }

    th:first-child, td:first-child {
        width: 316px;
    }

    .results_comp {
        display: flex;
        align-items: stretch;
        gap: 20px;

        .list {
            flex: 0 0 360px;
            height: 450px;

            .comp {
                padding: 12px;
                border-radius: 12px;
                font-weight: 700;
                line-height: 20px;
                cursor: pointer;

                &:hover {
                    background: #F1F3F6;
                }

                &.active {
                    background: #E5E7EB;
                }
            }
        }

        .info {
            flex-grow: 1;
            position: relative;

            .description {
                margin: 12px 0;
            }

            .chart {
                position: absolute;
                bottom: 0;
            }
        }
    }
}

.dlg_add_bulk {
    > div {
        width: 600px;
    }

    .upload_notices {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 10px;
        max-height: 300px;
        overflow-y: auto;

        .item {
            display: flex;
            border-radius: 8px;
            min-height: 45px;
            align-items: center;

            &.info {
                border: 1px solid #1BA049;
                background: url(/assets/img/notice-info.svg) no-repeat 10px 10px;
                background-color: #EAFCF1;
                background-size: 24px 24px;
                padding-left: 45px;
            }
    
            &.error {
                border: 1px solid #D92020;
                background: url(/assets/img/notice-error.svg) no-repeat 10px 10px;
                background-color: #FFEFEF;
                background-size: 24px 24px;
                padding-left: 45px;
            }
        }
    }
}

.dlg_add_domain {
    > div {
        min-height: 720px;
    }

    .new_domain_list {
        background: #fff;
        border-radius: 12px;
        padding: 4px 16px;
        box-shadow: 0px 3.2px 9px 0px #00000029;
        //box-shadow: 0px 0.6px 1.8px 0px #0000001A;
        //box-shadow: 0px -1.5px 6px 0px #0000000F;
        margin-top: 5px;

        .domain_name_item {
            display: block;
            line-height: 32px;
            color: var(--neutral90);
        }
    }
}

.content_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}