html, body {
  margin: 0;
  padding: 0;
  font-family: Mulish, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: var(--text-color-black);
  background: var(--grey-light);
  min-height: 100vh;
  overflow: hidden;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

a, a:active, a:focus, a:hover {
  text-decoration: none;
  outline: none;
  color: var(--orange-accent);
}

*, *::after, *::before {
  --orange-bg: #F8784F;
  --orange-accent: #F75623;
  --grey-light: #F1F3F6;
  --grey-med: #e5e7eb;
  --text-color-black: #232431;
  --text-color-grey: #717681;
  --grey2: #B0B5BD;
  --border-grey: #9BA0AA;
  --border-radius: 12px;
  --border-radius-btn: 6px;
  --layout-width: 1240px;
  --half-layout-width: 620px;
  --minus-half-layout-width: -620px;
  --red: #D92020;
  --neutral90: #23262D;
}

.btn-primary {
  --bs-btn-active-bg: transparent;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-color: var(--orange-accent);
  --bs-btn-font-size: 14px;
  --bs-btn-font-weight: 800;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: none;
  --bs-btn-active-color: var(--orange-accent);
  --bs-btn-hover-color: var(--orange-accent);
}

.dropdown-menu {
  --bs-dropdown-color: var(--text-color-black);
  --bs-dropdown-border-radius: 12px;
  --bs-dropdown-border-color: var(--grey-med);
  --bs-dropdown-padding-x: 4px;
  --bs-dropdown-padding-y: 4px;

  box-shadow: 0px 3.2px 9px 0px #00000029;
  box-shadow: 0px 0.6px 1.8px 0px #0000001A;
  box-shadow: 0px -1.5px 6px 0px #0000000F;
}

.dropdown-item {
  --bs-dropdown-item-padding-x: 8px;
  --bs-dropdown-item-padding-y: 0;
  --bs-dropdown-link-color: var(--text-color-black);
}

.dropdown-item.active, .dropdown-item:active {
  background: none;
  color: var(--text-color-black);
}

label {
  color: var(--text-color-grey);
  font-size: 14px;
  line-height: 20px;
}

label.req::after {
  content: '*';
  display: inline;
  color: #f00;
}

input, input:active, input:focus, input:focus-visible, textarea, textarea:active, textarea:focus, textarea:focus-visible {
  outline: none;
}

.modal-content {
  background: var(--grey-light);
  border: none;
  border-radius: 0;
}

.modal-header {
  border: none;
  padding: 20px;
  border-radius: 0;
}

.modal-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  
}

.modal-header .btn-close {
  border: none;
  background: url(/public/img/modal-close.svg) no-repeat center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}

.modal-body {
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0;
}

.tag {
  display: inline-block;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 12px;
}

.tag.grey {
  background: var(--grey-med);
}

.tag.orange {
  background: #feeee9;
}

.tag.green {
  background: #C3F4D3;
}

.tag.blue {
  background: #C4EAFF;
}

.orangetext {
  color: var(--orange-accent);
}

.sep {
  flex-grow: 1;
}

.vsep {
  width: 1px;
  height: 20px;
  background: var(--grey2);
  flex: 0 0 1px;
}

h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  padding: 0;
  margin: 0;
}

h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.dropdown button::after {
  content: '';
  display: none;
}

.dropdown .dropdown-item {
  border-radius: 12px;
  line-height: 32px;
}

/*.dropdown button {
  background: none;
  border: none;
  color: var(--orange-accent);
  font-weight: 800;
}

.dropdown .dropdown-menu {
  border: 1px solid var(--grey-med);
  border-radius: 12px;
  background: #fff;
  padding: 4px;

}

.dropdown button:hover, .dropdown button:active, .dropdown button:focus-visible, .dropdown button:focus {
  background: none !important;
  border: none !important;
  color: var(--orange-accent) !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown .dropdown-menu {
  border: 1px solid var(--grey-med);
  border-radius: 12px;
  background: #fff;
  padding: 4px;

  box-shadow: 0px 3.2px 9px 0px #00000029;
  box-shadow: 0px 0.6px 1.8px 0px #0000001A;
  box-shadow: 0px -1.5px 6px 0px #0000000F;
}

.dropdown .dropdown-item {
  border-radius: 12px;
  background: none;
  color: var(--text-color-black);
  line-height: 32px;
  padding: 0 12px;
}

.dropdown .dropdown-item:hover {
  background: #f2f2f2;
}*/

.info-date {
  background: url(/src/assets/img/date.svg) no-repeat center left;
  padding-left: 20px;
  line-height: 24px;
}

.info-person {
  background: url(/src/assets/img/person.svg) no-repeat center left;
  padding-left: 20px;
  line-height: 24px;
}

.bold {
  font-weight: 700;
}

.modal-body .react-datepicker-wrapper {
  display: block;
}

.loading_wrap {
  display: flex;
  justify-content: center;
}

.dropzone_msg {
  border-radius: 12px;
  padding: 24px;
  border: 1px dashed #9BA0AA;
  margin-bottom: 20px;
}