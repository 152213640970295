.tabcontrol {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 0;

    .tabContent {
        padding: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
    }
}

.tabsList {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--grey-med);
    list-style: none;
    padding: 0;
    gap: 32px;

    .tab {
        line-height: 36px;
        cursor: pointer;

        &.active {
            font-weight: 700;
            color: var(--orange-accent);
            border-bottom: 2px solid var(--orange-accent);
        }
    }
}