.select_outer {
    position: relative;
    
    .control {
        background: #fff;
        width: 100%;
        color: var(--text-color-black);
        border-radius: 6px;
        border: 1px solid var(--border-grey);
        font-size: 14px;
        box-shadow: none;
        min-height: 32px;   

        &.focused, &:hover {
            border-color: var(--orange-accent);
        }
    }

    .value_container {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 8px;
    }

    .indicators_container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .dropdown_indicator {
        padding-top: 0;
        padding-bottom: 0;
    }

    .indicator_separator {
        display: none;
    }

    .option {
        &:hover, &.selected {
            background: var(--orange-accent);
            color: #fff;
        }
    }

    &.error .control {
        border: 1px solid var(--red);
    }

    .error_msg {
        position: absolute;
        top: 100%;
        left: 0;
        line-height: 16px;
        color: var(--red);
    }
}