.button {
    display: inline-flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: var(--grey-light);
    border-radius: var(--border-radius-btn);
    border: none;
    cursor: pointer;
}