.button {
    display: inline-block;
    border: none;
    line-height: 32px;
    padding: 0 20px;
    text-align: center;
    color: #fff;
    background: var(--orange-accent);
    font-weight: 800;
    font-size: 14px;
    border-radius: var(--border-radius-btn);
    cursor: pointer;

    &.login {
        padding: 0;
        width: 172px;
        line-height: 40px;
    }

    &.empty {
        background: #fff;
        border: 1px solid var(--orange-accent);
        color: var(--orange-accent);
    }

    &.white {
        background: none;
        border: 1px solid #fff;
        color: #fff;
    }

    &.text {
        background: none;
        border: none;
        color: var(--text-color-black);
        padding: 0;
    }

    &.text-light-grey {
        background: none;
        border: none;
        color: var(--grey2);
        padding: 0;
    }

    &.orangetext {
        background: none;
        border: none;
        color: var(--orange-accent);
        padding: 0;
    }
}