.notices_list {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-right: var(--minus-half-layout-width);
    transition: all 1s;

    .notice {
        display: flex;
        padding: 10px;
        color: #000;
        margin-bottom: 20px;
        border-radius: 8px;
        max-width: 384px;
        opacity: 0;
        transition: opacity 2s, top 0.5s;
        position: relative;
        top: 400px;

        &.shown {
            opacity: 1;
            top: 0;
        }

        &.shownfinal {
            opacity: 1;
            top: 0;
            transition: none !important;
        }

        &.hidden {
            top: 0;
            opacity: 0;
        }

        .btn_close {
            display: block;
            background: url(/assets/img/notice-close.svg) no-repeat center center;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        &.info {
            border: 1px solid #1BA049;
            background: url(/assets/img/notice-info.svg) no-repeat 10px 10px;
            background-color: #EAFCF1;
            background-size: 24px 24px;
            padding-left: 45px;
        }

        &.error {
            border: 1px solid #D92020;
            background: url(/assets/img/notice-error.svg) no-repeat 10px 10px;
            background-color: #FFEFEF;
            background-size: 24px 24px;
            padding-left: 45px;
        }
    }
}