.table_wrap {
    min-height: 0;
    overflow: auto;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
        position: sticky;
        top: 0;
    }

    th {
        font-weight: 700;
        line-height: 20px;
        position: relative;
        vertical-align: top;
        background: #fff;
        border-bottom: 1px solid var(--grey-med);

        .inner {
            margin: 4px 0;
            padding: 6px 12px;

            small {
                display: block;
                font-size: 12px;
                line-height: 16px;
                margin-top: 2px;
                font-weight: 400;
                color: var(--text-color-grey);
            }
        }

        &:not(.th-selector):not(:last-child)::after {
            content: ' ';
            display: block;
            position: absolute;
            top: 4px;
            bottom: 4px;
            right: 0;
            width: 1px;
            background: var(--grey-med);
        }

        &.th-selector { 
            width: 30px;
            text-align: center;

            label::before {
                margin: 0 !important;
            }

        }
    }

    tr:not(:last-child) td {
        border-bottom: 1px solid var(--grey-med);
    }

    td {
        vertical-align: top;

        

        .inner {
            margin: 4px 0;
            padding: 6px 12px;

            small {
                display: block;
                font-size: 12px;
                line-height: 16px;
                margin-top: 2px;
                font-weight: 400;
                color: var(--text-color-grey);
            }

            a {
                display: block;
                font-size: 12px;
                line-height: 16px;
                margin-top: 2px;
                font-weight: 400;
                color: var(--orange-accent);
            }
        }

        

        &.td-selector {
            width: 30px;
            text-align: center;

            label::before {
                margin: 0 !important;
            }

            .inner {
                border-right: none;
            }
        }

        &.checked {
            background: #FEEEE9;
        }
    }
}