/* для элемента input c type="checkbox" */
.input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  /* для элемента label, связанного с .custom-checkbox */
  .input + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: var(--text-color-black);
  }

  /* создание в label псевдоэлемента before со следующими стилями */
  .input + label::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 4px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 11px 8px;
    vertical-align: top;
  }

  .general .input + label::before {
    border: 1px solid var(--orange-accent);
  }

  .black .input + label::before {
    border: 1px solid #717681;
  }

  /* стили при наведении курсора на checkbox */
  .input:not(:disabled):not(:checked) + label:hover::before {
    border-color: var(--orange-accent);
  }

  
  /* стили для активного чекбокса (при нажатии на него) */
  .input:not(:disabled):active + label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  /* стили для чекбокса, находящегося в фокусе */
  //.input:focus+label::before {
    //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  //}

  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  //.input:focus:not(:checked)+label::before {
    //border-color: #80bdff;
  //}

  /* стили для чекбокса, находящегося в состоянии checked */
  .input:checked + label::before {
    border-color: var(--orange-accent);
    background-color: var(--orange-accent);
    //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMTAuNDcxNCAxLjgwNDc0QzEwLjczMTggMS41NDQzOSAxMC43MzE4IDEuMTIyMjggMTAuNDcxNCAwLjg2MTkzNEMxMC4yMTExIDAuNjAxNTg0IDkuNzg4OTUgMC42MDE1ODQgOS41Mjg2IDAuODYxOTM0TDQgNi4zOTA1M0wxLjEzODA3IDMuNTI4NkMwLjg3NzcyMiAzLjI2ODI1IDAuNDU1NjEyIDMuMjY4MjUgMC4xOTUyNjIgMy41Mjg2Qy0wLjA2NTA4NzQgMy43ODg5NSAtMC4wNjUwODc0IDQuMjExMDYgMC4xOTUyNjIgNC40NzE0MUwzLjUyODYgNy44MDQ3NEMzLjc4ODk0IDguMDY1MDkgNC4yMTEwNiA4LjA2NTA5IDQuNDcxNCA3LjgwNDc0TDEwLjQ3MTQgMS44MDQ3NFoiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==);
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  .input:disabled + label::before {
    background-color: #e9ecef;
  }
