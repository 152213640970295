@import '../../assets/css/common';

.survey_section_header {
    display: flex;
    align-items: center;
    height: 60px;
    flex: 0 0 60px;
    cursor: pointer;

    .name {
        flex-grow: 1;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-left: 6px;
    }

    .section_progress {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 0 0 300px;
        justify-content: flex-end;

        
        .progress2 {
            flex: 0 0 160px;
        }
    }

    
}

.survey_section_body {
    .question {
        @include white-block;

        display: flex;
        align-items: stretch;
        gap: 10px;
        margin-bottom: 4px;
        padding: 8px;
        
        .q {
            flex-grow: 1;
            line-height: 20px;
            font-weight: 700;
            padding: 10px;

            .stripe {
                display: block;
                height: 8px;
                background: #D5D8DE;
                margin-bottom: 12px;

                &.active {
                    background: #32C665;
                }
            }

            .comment {
                position: relative;

                .comment_popup {
                    position: absolute;
                    width: 404px;
                    display: none;
                    background: #fff;
                    border: 1px solid #EAEAEA;
                    @include shadow;
                    border-radius: var(--border-radius);
                    padding: 12px;
                    z-index: 9;

                    &.shown {
                        display: block;
                    }

                    .btns {
                        display: flex;
                        gap: 8px;
                        margin-top: 14px;
                    }
                }
            }
        }

        .answer {
            flex: 0 0 160px;
            border-radius: 12px;
            padding: 6px;
            cursor: pointer;
            font-size: 13px;
            position: relative;

            &.simple {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.selected {
                background: #FEEEE9;
            }

            &.simple.selected {
                background: var(--grey-light);
            }

            &:hover {
                background: var(--grey-med);
            }

            .ans_selector {
                display: block;
                width: 18px;
                height: 18px;
                border: 1px solid var(--text-color-grey);
                border-radius: 50%;
            }

            &.selected .ans_selector {
                border: 5px solid var(--orange-accent);
            }

            .block {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
                cursor: wait;
            }
        }

        .btn_comment {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--orange-accent);
            font-weight: 800;
            cursor: pointer;
            white-space: nowrap;
            margin-top: 40px;
            font-size: 13px;
        }
    }
}